@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.full-width-image {
  zoom: 1;
  overflow: hidden;

  :global {
    .carousel-indicators {
      display: flex;
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
      margin: rfs-value(0 0 16px);

      &:before {
        content: '';
        display: block;
        background: rgba($nikon-text-dark-grey, 0.8);
        width: calc(100% - 40px);
        height: 32px;
        border-radius: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        top: auto;
        right: auto;
        z-index: 1;
      }

      button {
        border-radius: 100%;
        border: none;
        background-color: $nikon-white;
        opacity: 0.9;
        height: 14px;
        width: 14px;
        margin: rfs-value(9px 7px);
        position: relative;
        z-index: 2;

        &:first-child {
          margin-left: 10px;
        }
      }

      .active {
        background-color: $nikon-yellow !important;
      }
    }
  }

  &.single-image {
    :global {
      .carousel-indicators,
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: none;
      }
    }
  }

  .content-wrapper {
    position: relative;
    width: 100%;
    min-height: 350px;
    color: $nikon-white;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      min-height: 640px;
    }

    .background {
      position: absolute;
      z-index: 1;
      height: 100%;
      top: 0;
      object-fit: cover;
      transform: scale(1);
      transition: transform 1s ease-out, opacity 0.15s ease-out;
    }

    &:hover {
      .background {
        transform: scale(1.03);
      }
    }

    .text-container {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      .text-content {
        display: flex;
        flex-direction: column;
        &.left {
          align-items: flex-start;
        }
  
        &.center {
          align-items: center;
          text-align: center;
  
          .name-image {
            margin: 0 auto;
          }
        }
  
        &.right {
          align-items: flex-end;
          text-align: right;
        }

        @include media-breakpoint-up(lg) {
          width: 58.3333333333%;

          :global(.homepage-main-content) &,
          :global(.two-column-row) & {
            width: 100%;
          }
        }

        :global(.homepage-main-content) &,
        :global(.two-column-row) & {
          padding: rfs-value(0 30px);
        }

        &.bg-overlay {
          background: rgba(0, 0, 0, 0.8);
          padding: rfs-value(40px 35px);

          @include media-breakpoint-up(lg) {
            border-radius: 15px;
          }
        }
      }

      .name-image {
        max-height: 30px;
        max-width: 280px;
        margin-bottom: rfs-value(10px);

        @include media-breakpoint-up(md) {
          max-height: 250px;
          margin-bottom: rfs-value(15px);
        }

        :global(.two-column-row) & {
          max-height: 45px;

          @include media-breakpoint-up(md) {
            max-height: 45px;
          }
        }
      }

      .title {
        margin: 0;
        word-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        font-weight: $font-weight-semibold;
        font-size: 1.875rem;
        line-height: 1 !important;

        @include media-breakpoint-up(md) {
          line-height: 1.033333333333333 !important;
          font-size: 3.75rem;
        }

        :global(.two-column-row) & {
          font-size: rfs-value(30px);
          line-height: 1;
          margin-top: rfs-value(15px);

          @include media-breakpoint-up(md) {
            font-size: rfs-value(48px);
            line-height: 1.0333333;
          }
        }
      }

      h1.title {
        font-size: 2.06rem;

        @include media-breakpoint-up(md) {
          font-size: 4.25rem;
        }
      }

      .description {
        margin-top: 11px;
        font-size: rfs-value(14px);
        line-height: rfs-value(19.25px);

        @include media-breakpoint-up(md) {
          margin: 15px 0 0;
          font-size: rfs-value(24px);
          line-height: rfs-value(33.6px);
        }
      }

      .product-info {
        margin-top: 10px;

        .price {
          font-size: rfs-value(16px);
          color: $nikon-white;
          word-spacing: rfs-value(7px);

          &.discounted {
            color: $nikon-blood-orange;

            s {
              font-size: rfs-value(14px);
              color: $nikon-white;
            }
          }
        }

        .discount-info {
          font-size: rfs-value(12px);
          font-weight: $font-weight-bold;
          color: $nikon-red;
          text-transform: uppercase;
        }
      }

      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
        text-align: center;

        .name-image {
          margin: 0 auto;
        }
      }

      &.right {
        justify-content: flex-end;
      }
    }

    .link-buttons {
      padding: 0;
      margin-top: 15px;
      margin-bottom: 0;
      list-style-type: none;

      @include media-breakpoint-up(md) {
        margin-top: 30px;
      }

      .link-buttons-item {
        margin-bottom: 0;
        display: inline-block;

        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }

    .img-info-text {
      position: absolute;
      z-index: 3;
      padding: 12px 15px;
      right: 10px;
      bottom: 10px;
      background: rgba(0, 0, 0, 0.5);
      font-size: rfs-value(10px);
      line-height: 1;
      text-align: center;
      text-decoration: none;
      color: $nikon-white;

      @include media-breakpoint-up(md) {
        right: 30px;
        bottom: 45px;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
