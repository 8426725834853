// colors
$body-color: #000;
$nikon-grey-border: #c9c9c9;
$body-contrast-color: #f2f2f2;
$nikon-dark-grey: #404040;
$nikon-grey: #666;
$nikon-ada-grey: #767676;
$nikon-light-grey: #d8d8d8;
$nikon-light-border: #999;
$nikon-border-light-grey: #b2b2b2;
$nikon-light-grey-border: #d6d6d6;
$nikon-checkout-item-border: #cdcdcd;
$nikon-checkout-light-bg: #f1f1f1;
$nikon-checkout-footer-bg: #e6e6e6;
$nikon-black: #000000;
$nikon-white: #ffffff;
$nikon-light-grey-color: #cccccc;
$nikon-yellow: #ffdd00;
$nikon-yellow-hover: #ffcc00;
$nikon-blood-orange: #cc420c;
$nikon-red: #9b0b10;
$nikon-link: #337ab7;
$nikon-link-bold: #1d3994;
$nikon-link-bold-hover: #003c66;
$nikon-link-bolder: #0529af;
$nikon-bg-color: #fff;
$nikon-vapor: #fafafa;
$nikon-white-color: #f2f2f2;
$nikon-btn-white-bg-color: #efefef;
$nikon-text-dark-grey: #3c3c3c;
$nikon-text-grey78: #c7c7c7;
$nikon-box-shadow-color: #5b9dd9;
$input-focus-border-color: #000;
$input-focus-color: $nikon-text-dark-grey;
$checkout-step-border-grey: #d9d9d9;
$promo-background-color: #fbdcdf;
$promo-color: #90040a;
$nikon-light-grey-search-bg: #efefef;
$nikon-search-item-bg: #e6e6e6;
$nikon-bg-dark-grey: #1b1b1b;

// fonts
$font-family-generic: Arial, Helvetica, sans-serif;
$font-family-footer: Arial, sans-serif;
$font-family-base: GT-Eesti-Pro-Display, Arial, Helvetica, sans-serif;
$font-family-open-sans: open sans, Arial, Helvetica, sans-serif;
$font-family-serif: Times, Georgia, serif;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$headings-font-family: $font-family-base;
$input-btn-font-family: $font-family-base;
$icon-font: SSStandard, serif;

// grid
$container-max-widths: (
  sm: 1170px,
  md: 1170px,
  lg: 1170px,
  xl: 1170px,
  xxl: 1170px,
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1800px,
) !default;
$checkout-mobile-breakpoint: 1023px;
$checkout-tablet-breakpoint: 768px;
$checkout-desktop-breakpoint: 1024px;
$grid-gutter-width: 1.875rem;

// icons
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 745 1024' fill='currentColor'><path d='M744.727 294.788q0 5.172-4.31 11.205l-356.848 356.848q-11.205 11.205-22.411 0l-356.848-356.848q-11.205-11.205 0-22.411l108.606-108.606q11.205-11.205 22.411 0l237.037 237.899 237.037-237.899q11.205-11.205 22.411 0l108.606 108.606q4.31 6.034 4.31 11.205z' fill='currentColor'></path></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 745 1024' fill='currentColor'><path d='M744.727 294.788q0 5.172-4.31 11.205l-356.848 356.848q-11.205 11.205-22.411 0l-356.848-356.848q-11.205-11.205 0-22.411l108.606-108.606q11.205-11.205 22.411 0l237.037 237.899 237.037-237.899q11.205-11.205 22.411 0l108.606 108.606q4.31 6.034 4.31 11.205z' fill='currentColor'></path></svg>");
$accordion-icon-width: 12px;

// forms
$form-feedback-valid-color: $nikon-black;

// popovers (tooltips)
$popover-border-radius: 0;

// modals
$modal-header-border-width: 0;
$modal-inner-padding: 30px;
$modal-content-border-color: none;
$modal-content-border-radius: 15px;
$modal-footer-border-width: 0;
