@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.two-column-image-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  :global(.full-width) {
    .name-image {
      max-height: 45px !important;
    }
    .content {
      width: unset !important;
    }

    @include media-breakpoint-up(md) {
      h2 {
        font-size: 3rem !important;
      }
    }
  }


  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.9375rem;
  }

  :global(.panel-container) {
    margin: 0;

    & + :global(.panel-container) {
      margin-top: 15px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }
}
